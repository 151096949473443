import React from "react";

function App() {
  const numeros = Array.from({ length: 500 }, (_, index) => index + 1);

  return (
    <div className="flex flex-col min-h-screen">
      {/* Toolbar fixada no topo */}
      <header className="bg-blue-500 text-white p-4 fixed top-0 w-full">
        Toolbar Content
      </header>
      <main className="flex-grow pt-16 pb-16 overflow-y-scroll w-full">
        {/* Adicionamos a classe overflow-y-scroll */}
        <div className="container mx-auto px-4">
          {/* Removemos py-8 para centralizar verticalmente */}
          <ul className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {numeros.map((numero) => (
              <li className="bg-white p-4 shadow rounded">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              </li>
            ))}
          </ul>
        </div>
      </main>

      {/* Footer fixado na parte inferior */}
      <footer className="bg-gray-200 p-4 fixed bottom-0 w-full">
        Footer Content
      </footer>
    </div>
  );
}

export default App;
